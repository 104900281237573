import { Button } from "primereact/button";
import { useEffect, useState } from "react";
import { CompanyForm, emptyCompany, validateCompany } from "../../Companies/CompanyForm";
import { ConfirmDialog } from "../../Components/ConfirmDialog";
import client, { getData, patchData } from "../../feathers";
import { ENUM_ROUTES, ROUTES } from "../../Navigation/Routes";
import { useBreadcrumbsAdd, useBreadcrumbsUpdate } from "../../Persistence/Breadcrumbs";
import { useConfigurationsList, useDataviewsConfig, useFormatDate } from "../../Persistence/ConfigurationsContext";
import { useCurrentUser, useCurrentUserIsReaderFBiB, useUserSwitchMode } from "../../Persistence/CurrentUserContext";
import { useDataViewFilterUpdate, useDataViewPaginationUpdate } from '../../Persistence/DataViewFilterContext';
import { ReminderDialog } from '../../Reminders/ReminderDialog';
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from "../../Components/Icons";
import { addDays, isBefore } from "date-fns";
import { useRemindersList } from "../../Persistence/RemindersContext";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { openCompany, patchCompany } from "../../Companies/CompanyManager";
import { copyObject } from "../../snippets";
import { useFormik } from "formik";
import { EditDialog } from "../../Components/Dialogs/EditDialog";
import { ENUM_DATAVIEWS_CONFIG } from "../../Enums/ENUM_DATAVIEWS_CONFIG";
import { FBiBCaseCreateWizard } from "../FBiBCases/FBiBCaseCreateWizard";
import { TemplateDataViewPagination } from "../../Components/TemplateDataViewPagination";
import { Badge } from "primereact/badge";

const CLASSNAME = 'FBiBDashboard';

export const FBiBDashboard = () => {
	const formatDate = useFormatDate();
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.REMINDERS));
	const currentUser = useCurrentUser();
	const addBreadcrumb = useBreadcrumbsAdd();
	const isReader = useCurrentUserIsReaderFBiB();
	const remindersList = useRemindersList();
	const userSwitchMode = useUserSwitchMode();
	const configurationsList = useConfigurationsList();
	const dataViewFilterUpdate = useDataViewFilterUpdate();
	const setDataViewPagination = useDataViewPaginationUpdate();
	const updateBreadcrumbs = useBreadcrumbsUpdate();
	const [eaaCases, setEaaCases] = useState([])
	const [reminder, setReminder] = useState([])
	const [displayReminderDialog, setDisplayReminderDialog] = useState(false);
	const [displayConfirmDialogReminderDone, setDisplayConfirmDialogReminderDone] = useState(false);
	const [mounted, setMounted] = useState(false)
	const [displayCompanyDialog, setDisplayCompanyDialog] = useState(false);
	const [refreshTime, setRefreshTime] = useState(null)

	useEffect(() => {
		if (configurationsList.CaseStatusList) {
			client.service('utils').create({ method: 'DashboardCases', service: ENUM_SERVICES.FBIBCASES }).then(async (result) => {
				const statusList = configurationsList.CaseStatusList.filter(e => !['INACTIVE', 'CLOSED'].includes(e.alias)).map((status) => {
					const statusResult = result.find(e => e.status === status.alias)
					return { status, count: statusResult ? statusResult.count : 0 }
				})

				setEaaCases(statusList)
			})
			setMounted(true)
		}

	}, [configurationsList])

	const onClickEAACase = async (eaaStatus) => {
		const dataviewConfigCases = getDataviewConfig(ENUM_DATAVIEWS_CONFIG.FBIBCASES)
		const filters = dataviewConfigCases.filters
		for (let key of Object.getOwnPropertyNames(filters)) {
			filters[key].value = filters[key].field === 'status' ? [eaaStatus.alias] : (filters[key].field === 'responsible.lastname' ? [currentUser.id] : null)
		}
		//await dataViewFilterUpdate(dataviewConfig, filters);

		const paginationRows = dataviewConfigCases.pagination.paginationRows
		await setDataViewPagination(dataviewConfigCases, { paginationFirst: 0, paginationRows, currentPage: 0 })
		await dataViewFilterUpdate(dataviewConfigCases, filters);
		updateBreadcrumbs({ home: { icon: ENUM_ICONS.EAACASE }, items: [{ label: 'Fälle', route: ENUM_ROUTES.FBIB_CASES }] })
	}

	const renderEaaCases = () => {
		return eaaCases.map((eaaCase) => {
			return <div key={'eaacase_' + eaaCase.status.alias} onClick={() => { onClickEAACase(eaaCase.status) }} className="flex grid  align-content-start flex-grow-1 h-7rem justify-content-center surface-0 shadow-2 m-2 p-2 border-radius-sm  cursor-pointer">
				<div className="col">
					<div className="flex flex-column">
						<div className="text-lg">{eaaCase.status.display}</div>
						<div className={"mt-2 font-bold text-xl " + ('color-text-' + eaaCase.status.color)} >{`${eaaCase.count} ${eaaCase.count === 1 ? 'Fall' : 'Fälle'}`}</div>
						<div className="flex text-base w-full justify-content-end">Ansehen</div>
					</div>
				</div>
			</div>
		})
	}

	const handleEditReminder = async (entry) => {

		await getData('reminders', entry.id).then(async (reminder) => {
			if (reminder.reminded === -1) {
				reminder.reminded = 0
				await patchData(ENUM_SERVICES.REMINDERS, reminder)
			}
			setReminder(reminder);
			setDisplayReminderDialog(true);
		})
	}

	const handleSetReminderDone = async (reminder) => {
		setReminder(reminder);
		setDisplayConfirmDialogReminderDone(true);
	}


	const handleOpenRemindedObject = async (reminder) => {
		if (reminder.reminded === -1) {
			reminder.reminded = 0
			await patchData(ENUM_SERVICES.REMINDERS, reminder)
		}
		let route = ROUTES.FBIB_DASHBOARD //reminder.serviceName === 'companies' ? ROUTES['COMPANIES'] : (reminder.serviceName === 'eaa-cases' ? ROUTES['EAACASES'] : (reminder.serviceName === 'provideractivities' ? ROUTES['PROVIDERACTIVITIES'] : (reminder.serviceName === ENUM_SERVICES.ASSOCIATEDCONTACTS ? ROUTES['ASSOCIATEDCONTACTS'] : ROUTES['JOBPROFILES'])))
		switch (reminder.serviceName) {
			case ENUM_SERVICES.COMPANIES: route = ROUTES.FBIB_COMPANIES; break;
			case ENUM_SERVICES.EAACASES: route = ROUTES.EAACASES; break;
			case ENUM_SERVICES.PROVIDERACTIVITIES: route = ROUTES.PROVIDERACTIVITIES; break;
			case ENUM_SERVICES.ASSOCIATEDORGANIZATIONS: route = ROUTES.FBIB_ASSOCIATEDORGANIZATIONS; break;
			case ENUM_SERVICES.ASSOCIATEDCONTACTS: route = ROUTES.FBIB_ASSOCIATEDCONTACTS; break;
			case ENUM_SERVICES.ACTIVITIESINCLUSIONOFFICE: route = ROUTES.ACTIVITIES_INCLUSIONOFFICE; break;
			case ENUM_SERVICES.JOBPROFILES: route = ROUTES.JOBPROFILES; break;
			case ENUM_SERVICES.FBIBCASES: route = ROUTES.FBIB_CASES; break;
			case ENUM_SERVICES.FBIBPROVIDERACTIVITIES: route = ROUTES.FBIB_PROVIDERACTIVITIES; break;
			case ENUM_SERVICES.FBIBJOBPROFILES: route = ROUTES.FBIB_JOBPROFILES; break;
			case ENUM_SERVICES.TRAININGCENTERS: route = ROUTES.FBIB_TRAININGCENTERS; break;
			case ENUM_SERVICES.TRAININGCOURSE: route = ROUTES.FBIB_TRAININGCOURSES; break;
		}
		updateBreadcrumbs({ home: { icon: route.icon }, items: [{ label: route.label, route: route.route }, { label: '', route: route.route + '/' + reminder.serviceId }] })
	}
	const onReminderDone = async () => {
		reminder.reminded = '1'
		await patchData('reminders', reminder).then(() => {
			setRefreshTime(Date.now())
		})
	}
	/** formik Company */
	const formikCompany = useFormik({
		initialValues: copyObject(emptyCompany),
		validate: validateCompany,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});
	const renderContextMenu = (rowData) => {
		return <div className={'open-icon flex w-full h-1rem ' + (rowData.hideEdit ? 'justify-content-end' : 'justify-content-between ')}>
			{rowData.hideEdit ? '' : <i className={ENUM_ICONS.EYE} onClick={() => handleOpenRemindedObject(rowData)} />}
			{userSwitchMode || rowData.hideEdit ? '' : <i className={ENUM_ICONS.PENCIL + 'edit-icon'} onClick={() => handleEditReminder(rowData)} />}
			{userSwitchMode ? '' : <i className={ENUM_ICONS.CHECK + 'check-icon'} onClick={() => handleSetReminderDone(rowData)} />}
		</div>
	}

	const renderReminderDate = (rowData) => {
		const date = addDays(new Date(rowData.reminderDate), -3)
		const icon = isBefore(date, new Date()) ? <Icon ENUM_AWESOMEICON={ENUM_AWESOMEICON.BELL} size={ENUM_AWESOMEICON_SIZE.X1} className='text-500' /> : null
		return <div className='flex w-full'>
			<div className='flex flex-grow-1 pt-1'>{formatDate(rowData.reminderDate)}</div>
			<div className='flex col-2'>{icon}</div>
		</div>
	}

	const renderReminderIcon = (rowData, column) => {
		const list = configurationsList.ServiceList ? configurationsList.ServiceList : [];
		const entry = list.find(e => rowData[column.field] === e.alias);
		const classnameColor = entry.classnameColor;
		const badge = entry.functionBadge && entry.functionBadge(rowData) ? <Badge severity='danger' className='fa-badge' /> : '';
		return entry ? (entry.icon ? <i className={'flex w-full justify-content-center ' + entry.icon} /> : <Icon ENUM_AWESOMEICON={entry.awesomeIcon} size={ENUM_AWESOMEICON_SIZE.X1} className={'flex w-full justify-content-center ' + classnameColor} badge={badge} />) : rowData[column.field];
	}

	const columnsReminders = [
		{ filterAlias: 'col_01', field: "serviceName", style: { width: '110px' }, body: renderReminderIcon },
		{ filterAlias: 'col_02', field: "referenceName", style: { flexGrow: 1, flexBasis: '200px' } },
		{ filterAlias: 'col_03', field: "reminderDate", style: { width: '180px' }, body: renderReminderDate },
		{ filterAlias: 'col_04', field: "description", style: { flexGrow: 1, flexBasis: '200px' }, },
		{ style: { width: '100px' }, body: renderContextMenu },
	]

	return (
		<div className='mx-auto eaa-dataView select-none'>
			{!reminder ? '' :
				<ReminderDialog
					displayDialog={displayReminderDialog}
					setDisplayDialog={setDisplayReminderDialog}
					header={reminder.serviceName === 'companies' ? 'Unternehmen' : 'Fall'}
					name={reminder.name}
					serviceId={reminder.serviceId}
					serviceName={reminder.serviceName}
					userId={reminder.userId}
					onEditReminder={() => setRefreshTime(Date.now())}
					reminder={reminder} />
			}
			<ConfirmDialog
				title='Wiedervorlage abschliessen'
				message={`<span>Wiedervorlage <br/><b>${reminder.description}</b><br/> als bearbeitet abschliessen?</span>`}
				labelOk='Ja'
				handleOnClick={onReminderDone}
				displayConfirmDialog={displayConfirmDialogReminderDone}
				setDisplayConfirmDialog={setDisplayConfirmDialogReminderDone} />

			<EditDialog
				displayDialog={displayCompanyDialog}
				formik={formikCompany}
				labelButtonOk='Anlegen'
				header='Unternehmen anlegen'
				message='Lege ein neues Unternehmen an'
				patchForm={async () => {
					const company = { ...formikCompany.values, origin: 'FBIB' }
					await patchCompany(company, currentUser).then((company) => {
						addBreadcrumb(`${company.name}`, `${ENUM_ROUTES.FBIB_COMPANIES}/` + company.id);
					})
				}}
				setDisplayDialog={setDisplayCompanyDialog}
				form={<CompanyForm
					formik={formikCompany}
					readOnly={false}
					handleSelectExistingCompany={async (id) => { formikCompany.setTouched({}); openCompany(id, addBreadcrumb, true) }}
				/>}
			/>

			<div className="flex justify-content-between flex-wrap w-full pt-2">
				<div className="flex  font-semibold text-xl">
					{`Willkommen zurück, ${currentUser.firstname} ${currentUser.lastname}`}
				</div>
				<div className="flex justify-content-end gap-3">
					<FBiBCaseCreateWizard />
					<Button disabled={isReader} onClick={() => { formikCompany.setValues(copyObject(emptyCompany)); setDisplayCompanyDialog(true) }} className='flex  text-white' label='Unternehmen anlegen' />
				</div>
			</div>

			<div className="card shadow-2 p-4 mt-2 mb-4 bg-white border-round">
				<div className='font-bold mb-4'>Ihre FBiB Fallübersicht</div>
				<div className="flex justify-content-center flex-wrap gap-4">
					{renderEaaCases()}
				</div>
			</div>

			<div className="card shadow-2 p-4 my-4 bg-white border-round" style={{ height: 'calc(100vh - 400px)' }}>
				<div className='font-bold mb-2'>Ihre Wiedervorlagen</div>
				<TemplateDataViewPagination
					key='datatable-reminders'
					scrollHeight={'calc(100vh - 580px)'}
					classNameDataTable='flex '
					columns={columnsReminders}
					dataTableWidth='1430px'
					refreshTime={refreshTime}
					onDoubleClick={handleEditReminder}
					dataviewConfig={dataviewConfig}
				/></div>
		</div>
	)

}