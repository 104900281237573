import { useFormik } from "formik";
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsSuperAdmin } from "../../Persistence/CurrentUserContext";
import { UserProvider } from "../../Persistence/UserContext";
import { useState } from "react";
import { ToastError } from '../../Utils/ToastError';
import client from "../../feathers";
import { endOfDay, format } from "date-fns";
import { ENUM_SERVICES } from "../../Enums/ENUM_SERVICES";
import { useConfigurationsList } from "../../Persistence/ConfigurationsContext";
import { emptyStatistic, validateStatistik } from "../../Statistic/StatisticForm";
import { StatisticPerson } from "../../Statistic/StatisticPerson";
import { StatisticAll } from "../../Statistic/StatisticAll";

export const StatisticSheet = () => {
	const currentUser = useCurrentUser();
	const isSuperAdmin = useCurrentUserIsSuperAdmin();
	const isAdmin = useCurrentUserIsAdmin();
	const configurationsList = useConfigurationsList();
	const [toastParams, setToastParams] = useState(null);

	const showDialog = async (startDate, endDate, userId, organizationId) => {
		const statisticValues = { ...JSON.parse(JSON.stringify(emptyStatistic)), startDate, endDate, userId, organizationId }
		statisticValues.Integrationsamt = configurationsList.statistics.Integrationsamt
		formikStatistic.setValues(statisticValues)
		await onClickCreateStatistic(statisticValues)
	}

	const onClickCreateStatistic = async (statisticValues) => {

		const endDate = endOfDay(statisticValues.endDate)
		const data = { ...statisticValues, startDate: statisticValues.startDate.getTime(), endDate: endDate.getTime(), currentUser, method: 'statisticFBiB', printStopwatch: isSuperAdmin }

		await client.service(ENUM_SERVICES.UTILS).create(data).then(async (result) => {
			if (!statisticValues.asExcelSheet) {
				const fileName = format(new Date(), 'yyyy-MM-dd_HH-mm-ss') + '_FBiB_Statistik.pdf';
				const base64 = await fetch(result);
				var blob = await base64.blob();

				var file = new File([blob], fileName, { lastModified: new Date().getTime(), type: "application/pdf" });
				let exportUrl = URL.createObjectURL(file);
				var fileLink = document.createElement('a');
				fileLink.href = exportUrl;
				fileLink.download = fileName;
				fileLink.click();
			} else {
				import('file-saver').then(module => {
					if (module && module.default) {
						const data = new Blob([result], {
							type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
						});

						module.default.saveAs(data, format(new Date().getTime(), 'yyyy-MM-dd_HH-mm-ss') + '_BIH_Statistik.xlsx');
					}
				});
			}

		}).catch((error) => {
			setToastParams({ title: 'Bei der Erstellung der Statistik ist ein Fehler aufgesteten !', message: error.message })
		});
	}

	/** formik Statistik */
	const formikStatistic = useFormik({
		initialValues: JSON.parse(JSON.stringify(emptyStatistic)),
		validate: validateStatistik,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	return <div>
		<UserProvider> <StatisticPerson onClickCreateStatistic={showDialog} /></UserProvider>
		{!isAdmin && currentUser.visibilityMode !== 'ALL' ? '' : <StatisticAll onClickCreateStatistic={showDialog} />}
		<ToastError toastParams={toastParams} />
	</div>

}