import { useState, useEffect } from 'react';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useConfigurationsList, useDataviewsConfig } from '../Persistence/ConfigurationsContext';
import { getSortedList } from '../snippets';
import { ENUM_ICONS } from '../Components/Icons';
import { useCurrentUserIsAdmin } from '../Persistence/CurrentUserContext';
import { useCaseEventsCountRecords } from '../Persistence/CaseEventsContext';
import { ENUM_DATAVIEWS_CONFIG } from '../Enums/ENUM_DATAVIEWS_CONFIG';
import { TemplateDataViewPagination } from '../Components/TemplateDataViewPagination';

const CLASSNAME = 'OverviewCaseEventsDataview'

export const OverviewCaseEventsDataview = () => {
	const configurationsList = useConfigurationsList()
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.OVERVIEWS_CASEEVENTS));
	const isAdmin = useCurrentUserIsAdmin();
	const caseEventsCountRecords = useCaseEventsCountRecords();
	const [eventClassifierStructure, setEventClassifierStructure] = useState([]);
	const [eaaCaseEventsList, setEaaCaseEventsList] = useState();
	const [countRecords, setCountRecords] = useState(0);
	const [mounted, setMounted] = useState(false);

	useEffect(() => {
		setCountRecords(caseEventsCountRecords);
	}, [caseEventsCountRecords])

	useEffect(() => {
		if (configurationsList.CaseEventClassifierStructure) {
			setEventClassifierStructure(JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)));
		}
	}, [configurationsList])

	useEffect(() => {
		setMounted(true)
	}, [eventClassifierStructure])

	const renderEventtype = (rowData) => {
		const eventType = !configurationsList ? undefined : configurationsList.EventTypeList.find(e => e.alias === rowData.eventType)
		return eventType ? eventType.display : rowData.eventType
	}

	const renderCreator = (rowData) => {
		return (rowData.creator ? rowData.creator.lastname + ', ' + rowData.creator.firstname : 'n.n.')
	}

	const renderClassifiers = (rowData) => {
		const eventClassifiersList = rowData.eventClassifiers;

		const classifierStructure = JSON.parse(JSON.stringify(configurationsList.CaseEventClassifierStructure)).map((structure) => {
			const items = structure.items.filter(item => eventClassifiersList.includes(item.caseEventClassifier));
			return { ...structure, items }
		}).filter(structure => structure.items.length > 0)

		return <div className='flex flex-column' key={'div-classifier_' + rowData.id} >
			{classifierStructure.map((structure) => {
				const items = getSortedList(structure.items, 'caseEventClassifier', false);
				return <div key={'div_' + structure.headline + '_' + rowData.id}>
					<div className='flex p-0 m-0 text-600 text-base font-semibold' key={'classifier_' + structure.headline}>
						{structure.headline}
					</div>

					{items.map((item) => {
						const caseEventClassifierOption = configurationsList.CaseEventClassifierList.find(e => e.caseEventClassifier === item.caseEventClassifier);
						return <div className={caseEventClassifierOption.hide === 1 ? 'hidden' : 'flex'} key={'classifier_' + item.caseEventClassifier}>
							<div className='mr-1'><i className={ENUM_ICONS.CHECK_SQUARE}></i></div>
							<div className=''>{item.dataview} </div>
						</div>

					})}

				</div>
			})}
		</div>

	}
	const columnsCaseEvents = [
		{ filterAlias: 'col_01', field: "eaaCase.displayId", style: { width: '100px' } },
		{ filterAlias: 'col_02', field: "eaaCase.name", style: { flexGrow: 1, flexBasis: '150px' } },
		{ filterAlias: 'col_03', field: "eventDate", style: { width: '180px' }, body: 'renderDate' },
		{ filterAlias: 'col_04', field: "company.name", style: { flexGrow: 1, flexBasis: '150px' }, className: '', body: (rowData) => { return (rowData.company) ? rowData.company.name : '' } },
		{ filterAlias: 'col_05', field: "creator.id", style: { width: '250px' }, className: (isAdmin ? '' : 'hidden'), body: renderCreator },
		{ filterAlias: 'col_06', field: "eventType", style: { width: '200px' }, body: renderEventtype },
		{ filterAlias: 'col_07', field: "caseRequestsAmountRequests", style: { width: '130px' } },
		{ filterAlias: 'col_08', field: "eventClassifierIds", style: { width: '350px' }, body: renderClassifiers },
	]

	return <div className='w-full overflow-auto'>
		<TemplateDataViewPagination
			showOpenLinkColumn={true}
			key='datatable-overviews-caserequests'
			scrollHeight={'calc(100vh - 260px)'}
			classNameDataTable='flex w-full'
			ROUTE={ENUM_ROUTES.EAACASES}
			routeIdField='eaaCaseId'
			columns={columnsCaseEvents}
			dataTableWidth='100%'
			dataviewConfig={dataviewConfig}
		/>
	</div>
}