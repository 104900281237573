import { getAssociatedContact } from "../AssociatedContacts/AssociatedContactsManager";
import { ENUM_SERVICES } from "../Enums/ENUM_SERVICES"
import { ENUM_ROUTES } from "../Navigation/Routes";
import { getData, patchData } from "../feathers"
import { getSortedList } from "../snippets";


export const openAssociatedOrganization = async (id: number, addBreadcrumb: Function) => {
	await getData(ENUM_SERVICES.COMPANIES, id).then((associatedOrganization) => {
		addBreadcrumb(`${associatedOrganization.name}`, `${ENUM_ROUTES.ASSOCIATEDORGANIZATIONS}/` + id);
	})
}

export async function getAssociatedOrganization(associatedOrganizationId: any, setAssociatedOrganization: Function, isReader: boolean, setReadonly: Function, setToastParams: Function) {
	return await getData(ENUM_SERVICES.ASSOCIATEDORGANIZATIONS, associatedOrganizationId).then(async (associatedOrganization: any) => {
		const associatedContactOrganization = associatedOrganization.associatedContacts.find((e: any) => e.isOrganizationEntry === 1)
		if (associatedContactOrganization) {
			const { email, fax, mobile, phone, displayId } = await getData(ENUM_SERVICES.ASSOCIATEDCONTACTS, associatedContactOrganization.id)
			associatedOrganization = { ...associatedOrganization, displayId, email, fax, mobile, phone, associatedContactOrganizationId: associatedContactOrganization.id }
		}
		if (setReadonly) {
			setReadonly(isReader || !associatedOrganization.active)
		}

		associatedOrganization.associatedContacts = getSortedList(associatedOrganization.associatedContacts, 'order', true)
		if (setAssociatedOrganization) {
			setAssociatedOrganization(associatedOrganization)
		}
		associatedOrganization.country = associatedOrganization.country || 'Deutschland'
		return associatedOrganization
	}).catch((error) => {
		if (setToastParams) {
			setToastParams({ title: 'Daten konnten nicht ermittelt werden', message: error.message })
		} else {
			throw error
		}
	});
}
export async function patchAssociatedOrganization(associatedOrganization: any, setAssociatedOrganization: Function, isReader: boolean, setReadonly: Function, setToastParams: Function) {
	if (!associatedOrganization.regionId) {
		associatedOrganization.regionId = null
	}
	await patchData(ENUM_SERVICES.ASSOCIATEDORGANIZATIONS, associatedOrganization).then(async () => {
		const { email, fax, mobile, phone, associatedContactOrganizationId, active, regionId } = associatedOrganization
		if (associatedContactOrganizationId) {
			await getAssociatedContact(associatedContactOrganizationId).then(async (result) => {
				await patchData(ENUM_SERVICES.ASSOCIATEDCONTACTS, { ...result, email, fax, mobile, phone, active, regionId })
			})
		}
		await getAssociatedOrganization(associatedOrganization.id, setAssociatedOrganization, isReader, setReadonly, setToastParams);
	})
}

export const toggleStatus = async (associatedOrganization: any, setAssociatedOrganization: Function, contactId: string, isReader: boolean, setReadonly: Function, setToastParams: Function, setAssociatedContact: Function) => {
	associatedOrganization.active = associatedOrganization.active ? 0 : 1;
	return await patchAssociatedOrganization(associatedOrganization, setAssociatedOrganization, isReader, setReadonly, setToastParams)
}
