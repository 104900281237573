

import { useCallback, useEffect, useState } from "react"
import { useTabViewsIndex, useTabViewsIndexUpdate } from '../../Persistence/CurrentUserContext';
import { FBiBStatisticCaseRequests } from './FBiBStatisticCaseRequests';
import { Menubar } from 'primereact/menubar';
import { ENUM_AWESOMEICON, ENUM_AWESOMEICON_SIZE, ENUM_ICONS, Icon } from '../../Components/Icons';
import { ENUM_DATAVIEWS_CONFIG } from '../../Enums/ENUM_DATAVIEWS_CONFIG';
import { ENUM_TABVIEWS } from '../../Enums/ENUM_TABVIEWS';
import { ENUM_STATISTICS } from '../../Enums/ENUM_STATISTICS';
import { StatisticSheet } from "./StatisticSheet";

export const FBiBStatistic = () => {

	const menubarItemSelected = useTabViewsIndex();
	const setMenubarItemSelected = useTabViewsIndexUpdate();
	const [items, setItems] = useState(null)
	const [dataView, setDataView] = useState();

	useEffect(() => {
		const value = menubarItemSelected ? menubarItemSelected[ENUM_TABVIEWS.FBIB_STATISTICS] || ENUM_STATISTICS.FBIB : ENUM_STATISTICS.FBIB
		setMenuItems(value)
		switch (value) {
			case ENUM_STATISTICS.FBIB: setDataView(<StatisticSheet />); break;
			case ENUM_STATISTICS.CASEREQUESTS: setDataView(<FBiBStatisticCaseRequests />); break;
		}
	}, [menubarItemSelected])

	const setMenuItems = useCallback((enum_statistic) => {
		const items = [
			{
				label: 'FBiB-Statistik',
				icon: ENUM_ICONS.STATISTICS,
				className: ([ENUM_STATISTICS.FBIB].includes(enum_statistic)) ? 'statisticsfbib-menubar-selected' : '',
				command: () => changeDataView(ENUM_STATISTICS.FBIB, <StatisticSheet />)
			},
			{
				label: 'Antrags-Statistik',
				icon: <Icon
					ENUM_AWESOMEICON={ENUM_AWESOMEICON.CASEREQUESTS}
					size={ENUM_AWESOMEICON_SIZE.X1}
					className={([ENUM_STATISTICS.CASEREQUESTS].includes(enum_statistic)) ? 'mr-2 color-text-fbib navigation-entry-active' : 'mr-2'}
				/>,
				className: ([ENUM_STATISTICS.CASEREQUESTS].includes(enum_statistic)) ? 'statisticsfbib-menubar-selected' : '',
				command: () => changeDataView(ENUM_STATISTICS.CASEREQUESTS, <FBiBStatisticCaseRequests />)
			}
		]
		setItems(items);
	}, [])

	const changeDataView = (enum_dataview_config, component) => {
		setDataView(component);
		setMenubarItemSelected({ ...menubarItemSelected, [ENUM_TABVIEWS.FBIB_STATISTICS]: enum_dataview_config })
	}

	return (
		<div className='mx-auto eaa-dataView ' style={{ maxWidth: 'calc(100vw - 100px)', maxheight: 'calc(100vh - 60px)' }} >
			<div className='flex flex-column w-full border-1 border-gray-200 bg-white' >
				<Menubar model={items} className='border-none border-bottom-1' />
				<div className='z-0 mx-3 my-1'>{dataView}</div>
			</div>

		</div>
	)

}