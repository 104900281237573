import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { ConfirmDialog } from '../Components/ConfirmDialog';
import { ENUM_ICONS } from '../Components/Icons';
import { OverlayPanelMenu } from "../Components/OverlayPanelMenu";
import { createPassword, deleteData, getData, patchData } from '../feathers';
import { ChangePassword } from '../Login/ChangePassword';
import { ENUM_ROUTES } from '../Navigation/Routes';
import { useConfigurationsList, useDataviewsConfig, useFormatDate } from '../Persistence/ConfigurationsContext';
import { useCurrentUser, useCurrentUserIsAdmin, useCurrentUserIsReader, useCurrentUserIsSuperAdmin, useUserSwitchMode, useUserSwitchModeUpdate } from '../Persistence/CurrentUserContext';
import { useTaskareasList } from '../Persistence/TaskareasContext'
import { useUserList } from "../Persistence/UserContext";
import { emptyUser, UserForm, validateUser } from './UserForm';
import { UserProfile } from './UserProfile';
import { useFormik } from 'formik';
import { EditDialog } from '../Components/Dialogs/EditDialog';
import { useRegionsList } from "../Persistence/RegionsContext";
import { ENUM_DATAVIEWS_CONFIG } from "../Enums/ENUM_DATAVIEWS_CONFIG";
import { getSortedList } from "../snippets";
import { parseISO } from "date-fns";
import { Tooltip } from 'primereact/tooltip';
import { TemplateDataViewPagination } from "../Components/TemplateDataViewPagination";


export const UserAdministration = () => {
	const getDataviewConfig = useDataviewsConfig();
	const [dataviewConfig, setDataviewConfig] = useState(getDataviewConfig(ENUM_DATAVIEWS_CONFIG.USERADMINISTRATION));
	const formatDate = useFormatDate();
	const currentUser = useCurrentUser();
	const isAdmin = useCurrentUserIsAdmin();
	const isReader = useCurrentUserIsReader();
	const isSuperAdmin = useCurrentUserIsSuperAdmin();
	const setUserSwitchMode = useUserSwitchModeUpdate();
	const userSwitchMode = useUserSwitchMode();
	const configurationsList = useConfigurationsList();
	const usersList = useUserList();
	const taskareasList = useTaskareasList();
	const regionsList = useRegionsList();
	const [eaamoduleFBiB, setEaamoduleFBiB] = useState(false);
	const [refreshTime, setRefreshTime] = useState(null)
	const [user, setUser] = useState();
	const [displayUserDialog, setDisplayUserDialog] = useState(false);
	const [displayConfirmDialog, setDisplayConfirmDialog] = useState(false);
	const [displayPasswordDialog, setDisplayPasswordDialog] = useState(false);

	const navigate = useNavigate();

	useEffect(() => {
		if (!isAdmin && !userSwitchMode) {
			navigate('/' + ENUM_ROUTES.DASHBOARD)
		}
	}, [currentUser, userSwitchMode, navigate])

	useEffect(() => {
		setEaamoduleFBiB(configurationsList.eaaModules.FBIB === '1')
	}, [configurationsList])


	const handleHidePasswordDialog = () => {
		setDisplayPasswordDialog(false);
	}

	const getUser = async (id) => {
		await getData('users', id).then((user) => {
			user.permissions = configurationsList.RolesList.find((entry) => entry.alias === user.permissions)
			user.permissionsFBiB = configurationsList.RolesList.find((entry) => entry.alias === user.permissionsFBiB)
			user.mobilenumber = user.mobilenumber || '';
			user.phonenumber = user.phonenumber || '';
			user.addToRecipientList = user.addToRecipientList || 0;
			user.regions = user.regions.map(e => regionsList.find(region => e.id === region.id));
			user.taskareas = user.taskareas.map(e => taskareasList.find(taskarea => e.id === taskarea.id))
			user.userFTEs = user.userFTEs.map((userFTE) => { userFTE.date = parseISO(userFTE.date); return userFTE })
			user.userFTEs = getSortedList(user.userFTEs, 'date', true)

			user.dateLastFTE = user.userFTEs.length > 0 ? user.userFTEs[0].date : null
			user.lastFTE = user.userFTEs.length > 0 ? user.userFTEs[0].fte : null

			let additionalOrgIds = (!user.additionalOrganizationIds || user.additionalOrganizationIds === '' ? [] : user.additionalOrganizationIds.split(','))
			user.additionalOrgIds = additionalOrgIds.map(e => parseInt(e))
			user.roles = user.roles.map(e => e.alias).join('')
			formikUser.setValues(user)
			setUser(user);
		})
	}

	const handleSelectUser = async (entry) => {
		await getUser(entry.id)
		setDisplayUserDialog(true);
	}

	const handleChangePassword = async (id) => {
		await getData('users', id).then((_user) => {
			setUser(_user);
			setDisplayPasswordDialog(true);
		})
	}
	const handleChangeUser = async (id) => {
		await getData('users', id).then((user) => {
			setUserSwitchMode(user);
		})
	}

	const renderContextMenu = (rowData) => {
		const items = [{ label: 'Nutzer*in ändern', icon: ENUM_ICONS.PENCIL, command: () => handleSelectUser(rowData), disabled: isReader },];
		if (rowData.id !== currentUser.id) {
			items.push({ label: 'Passwort ändern', icon: ENUM_ICONS.PENCIL, command: () => handleChangePassword(rowData.id) });
		}

		if (rowData.active === 1) {
			items.push({ label: 'Nutzer*in inaktiv setzen', icon: ENUM_ICONS.CROSS, command: async () => { await getUser(rowData.id); setDisplayConfirmDialog(true) }, disabled: !isAdmin })
		} else {
			items.push({ label: 'Nutzer*in aktiv setzen', icon: ENUM_ICONS.CHECK, command: async () => { await getUser(rowData.id); setDisplayConfirmDialog(true) }, disabled: !isAdmin })
		}
		if (currentUser.isAdmin || userSwitchMode) {
			items.push({ label: 'Identität annehmen', icon: ENUM_ICONS.USER, command: () => handleChangeUser(rowData.id) });
		}

		return <div>
			<OverlayPanelMenu items={items} />
		</div>
	}

	const patchUser = async () => {
		//formikUser.values.organizationId = formikUser.values.organization ? formikUser.values.organization.id : null;
		formikUser.values.permissions = formikUser.values.permissions.alias
		formikUser.values.permissionsFBiB = formikUser.values.permissionsFBiB ? formikUser.values.permissionsFBiB.alias : 'READER'
		formikUser.values.displayname = formikUser.values.firstname + " " + formikUser.values.lastname
		const user = formikUser.values
		user.additionalOrganizationIds = user.additionalOrgIds.join(',')
		let patchedUser = user;
		if (!user.id) {
			patchedUser = await patchData('users', user);
		}
		delete patchedUser.password
		for (let entryFTE of user.userFTEs) {
			if (entryFTE.delete && entryFTE.id) {
				await deleteData('user-fte', entryFTE.id)
			} else {
				await patchData('user-fte', { ...entryFTE, userId: patchedUser.id })
			}
		}
		await patchData('users', patchedUser).then(async () => {
			setRefreshTime(Date.now())
			setDisplayUserDialog(false);
		})
	}

	const onClickNewUser = () => {
		formikUser.setValues({ ...emptyUser, password: createPassword(false) })
		setDisplayUserDialog(true);
	}

	const generateRecipientList = () => {
		const recipientList = usersList.filter(e => e.addToRecipientList === 1).map(e => e.email)
		navigator.clipboard.writeText(recipientList.join('\n'))
		alert('Verteilerliste:' + recipientList.length + '\n' + recipientList.join('\n') + '\n\nin die Zwischenablage kopiert')
	}

	const formikUser = useFormik({
		initialValues: { ...emptyUser },
		validate: validateUser,
		enableReinitialze: true,
		validateOnMount: true,
		validateOnChange: true,
	});

	const renderEmail = (row) => {
		return <div className='flex max-w-1rem'><a href={`mailto:${row.email}`}>{row.email}</a></div>
	}
	const renderRecipients = (rowData) => {
		return rowData.addToRecipientList === 1 ? 'ja' : 'nein'
	}
	const renderLoginAllowed = (rowData) => {
		return rowData.loginAllowed === 1 ? 'ja' : 'nein'
	}
	const renderFTE = (rowData) => {
		return <div className='w-full' >
			<Tooltip target={'.lastFTE_' + rowData.id} content={`seit: ${formatDate(rowData.dateLastFTE)}`} />
			<div className={'lastFTE_' + rowData.id} >{rowData.lastFTE === null ? '' : rowData.lastFTE.toLocaleString('de-DE', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
		</div>
	}

	const columns = [
		{ filterAlias: 'col_09', field: "id", style: { width: '70px' }, alignFrozen: "left", frozen: true },
		{ filterAlias: 'col_01', field: "organizationName", style: { flexGrow: 1, flexBasis: '200px' }, body: null, alignFrozen: "left", frozen: true },
		{ filterAlias: 'col_02', field: "displayname", style: { flexGrow: 1, flexBasis: '200px' }, alignFrozen: "left", frozen: true },
		{ filterAlias: 'col_03', field: "permissions", style: { width: '220px' }, body: 'renderList' },
		{ filterAlias: 'col_04', field: "active", style: { width: '200px' }, body: 'renderTag' },
		{ filterAlias: 'col_05', field: "loginAllowed", style: { width: '90px' }, body: renderLoginAllowed },
		{ filterAlias: 'col_06', field: "addToRecipientList", style: { width: '110px' }, body: renderRecipients },
		{ filterAlias: 'col_07', field: "lastFTE", style: { width: '70px' }, body: renderFTE },
		{ filterAlias: 'col_08', field: "email", style: { flexGrow: 1, flexBasis: '250px' }, body: renderEmail },
		{ filterAlias: 'col_10', field: "countAdditionalOrganization", style: { width: '110px' } },
		{ style: { width: '30px' }, body: renderContextMenu, alignFrozen: "right", frozen: true },
	]

	return (
		<div className='mx-auto eaa-dataView' style={{ maxWidth: 'calc(100vw - 100px)' }}>
			<ConfirmDialog title='Status ändern' message={'Nutzer: ' + (user ? user.lastname + ', ' + user.firstname : '') + (user && user.active === 0 ? ' aktivieren?' : ' deaktivieren?')} labelOk='Ja'
				handleOnClick={() => { user.active = (user.active === 1 ? 0 : 1); patchUser(user) }} displayConfirmDialog={displayConfirmDialog} setDisplayConfirmDialog={setDisplayConfirmDialog} />

			<Dialog header={'Passwort ändern: ' + (user ? user.displayname : '')} visible={displayPasswordDialog} className='eaa-passworddialog' onHide={() => setDisplayPasswordDialog(false)}>
				<ChangePassword user={user} currentUser={currentUser} hrMode={true} hideDialog={handleHidePasswordDialog} hideLogo={true} />
			</Dialog>

			<EditDialog
				form={<UserForm formik={formikUser} key='user-form' />}
				formik={formikUser}
				patchForm={patchUser}
				header={formikUser.values.id ? 'Nutzerdaten ändern' : 'Neue/r Nutzer*in anlegen'}
				message={formikUser.values.id ? 'Hier können sie den/die Nutzer*in: ' + formikUser.values.displayname + ' ändern.' : undefined}
				setDisplayDialog={setDisplayUserDialog}
				displayDialog={displayUserDialog}
			/>
			{currentUser.permissions !== 'ADMIN' && !userSwitchMode ?
				<UserProfile handleEditUser={handleSelectUser} handleChangePassword={handleChangePassword} /> :
				<div className='overflow-auto' style={{ maxWidth: 'calc(100vw - 100px)' }}>
					<TemplateDataViewPagination
						key='datatable-overviews-eaacases'
						scrollHeight={'calc(100vh - 260px)'}
						classNameDataTable='flex w-full'
						buttons={[
							<Button key={'button-new-user'} className='flex text-white' label='Nutzer*in anlegen' onClick={onClickNewUser} />,
							<Button key={'button-new-user'} className={isSuperAdmin ? 'flex  text-white' : 'hidden'} label='Verteilerliste' onClick={generateRecipientList} />,
							<Button key={'button-create-error'} className={isSuperAdmin ? 'flex  text-white' : 'hidden'} label='Fehler produzieren' onClick={() => { throw new Error('TEst Fehler') }} />]}
						columns={columns}
						dataTableWidth='100%'
						refreshTime={refreshTime}
						onDoubleClick={handleSelectUser}
						dataviewConfig={dataviewConfig}
					/>
				</div>
			}
		</div>
	)
}